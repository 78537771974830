<template>
    <v-container
        class="gradient-background">
    <v-form
      ref="form"
      lazy-validation>
        <v-row justify="center">
            <v-col cols="7">
                <v-img
                    class="mt-8 mb-8"
                    src="../assets/logo.png"
                    width="100%"></v-img>
            </v-col>
        </v-row>
      <v-row justify="center">
        <v-col cols="10" style="padding-top:0">
          <v-text-field
              v-model="newPassword"
              ref="newPassword"
              :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPassword ? 'text' : 'password'"
              label="New Password"
              filled
              background-color="primary"
              color="textDarkBg"
              rounded
              :rules="[rules.required, rules.minCharacters]"
              @click:append="showNewPassword = !showNewPassword"
              dense></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
              v-model="confirmNewPassword"
              ref="confirmNewPassword"
              :append-icon="showConfirmNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showConfirmNewPassword ? 'text' : 'password'"
              label="Confirm New Password"
              filled
              background-color="primary"
              color="textDarkBg"
              rounded
              :rules="[rules.required, rules.passwordMatch]"
              @click:append="showConfirmNewPassword = !showConfirmNewPassword"
              dense></v-text-field>
        </v-col>
      </v-row>
        <v-row justify="center">
            <v-col cols="10">
              <v-btn
                  class="text--secondary text-capitalize"
                  block
                  color="secondary"
                  depressed
                  large
                  rounded
                  @click="changePassword"
                  >
                Change Password
                <v-icon
                  class="text--primary  ml-2"
                  dark>
                mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>

<script>
import Vue from 'vue';
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
  mixins:[loginRegisterMixin],
  props:['email'],
  data() {
    return {
      newPassword: '',
      confirmNewPassword: '',
      showNewPassword: false,
      showConfirmNewPassword: false,
      rules: {
        required: value => !!value || 'Required.',
        passwordMatch: value => value === this.newPassword || 'Password not Match',
        minCharacters: value => value.length >= 8 || 'Password should be minimum of 8 characters'
      }
    };
  },
  methods: {
    changePassword() {
      if(this.$refs.form.validate())
        this.updatePassword(this.email, this.newPassword, this.confirmPasswordChanged);
    },
    confirmPasswordChanged() {
      alert('Password changed successfully');
      this.$router.push(Vue.prototype.$appRoutes.SignIn);
    }
  }
}
</script>

import axios from "axios";
import Vue from "vue";

export const groupMixin = {
    methods: {
        createGroup(name, image, onSuccess) {
            var data = new FormData();
            data.append('name', name);
            data.append('description', 'test 1234567899');
            data.append('image', image);

            var config = {
                header: {
                    'Content-Type' : 'multipart/form-data'
                }
            }
            axios.post(process.env.VUE_APP_API_ROOT + 'api/create_group', data, config)
                .then((response) => {
                    Vue.prototype.$createdGroupID = response.data;
                    if (onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        joinGroup(code, onSuccess) {
            var data = {
                verification_code: 12345,
            };
            axios.post(process.env.VUE_APP_API_ROOT + 'api/join_group_by_code', data, { params: {
                code
            }})
            .then((response) => {
                if (onSuccess != null)
                    onSuccess(response.data);
            }, (error) => {
                alert(error.response.data.message);
            })
        },
        getGroupDetails(group_id, onSuccess) {
            axios.get(process.env.VUE_APP_API_ROOT + 'api/group_details', { params: {
                group_id
            }})
            .then((response) => {
                var currentGroup = response.data;
                currentGroup[0].transaction = currentGroup[0].transaction.reverse();
                this.$session.set('currentGroup', currentGroup);
                if (onSuccess != null)
                    onSuccess();
            }, (error) => {
                alert(error.response.data.message);
            })
        },
        getUserGroups(onSuccess) {
            axios.get(process.env.VUE_APP_API_ROOT + 'api/my_groups')
                .then((response) => {
                    Vue.prototype.$userGroups = response.data;
                    if (onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        openGroup() {
            this.$router.push(Vue.prototype.$appRoutes.Group);
        },
        updateGroup(name, groupID, image,onSuccess) {
            var data = new FormData();
            data.append('name', name);
            data.append('group_id', groupID);
            data.append('image', image);

            var config = {
                header: {
                    'Content-Type' : 'multipart/form-data'
                }
            }

            axios.post(process.env.VUE_APP_API_ROOT + 'api/update_group', data, config)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        }
    }
}
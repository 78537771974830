<template>
    <v-container
        class="gradient-background">
    <v-form
        ref="form"
        lazy-validation>
        <v-row justify="center">
            <v-col cols="7">
                <v-img
                    class="mt-8 mb-8"
                    src="../assets/logo.png"
                    width="100%"
                    ></v-img>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
                <v-text-field
                v-model="name"
                ref="name"
                label="Name"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required]"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10" style="padding-top:0">
                <v-text-field
                v-model="phone"
                id="phone"
                label="Telephone Number"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                type="phone"
                :rules="[rules.required, rules.phone]"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10" style="padding-top:0">
                <v-text-field
                v-model="email"
                id="email"
                label="Email"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                type="email"
                :rules="[rules.required, rules.validEmail]"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10" style="padding-top:0">
                <v-text-field
                v-model="password"
                id="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required, rules.minCharacters]"
                @click:append="showPassword = !showPassword"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10" style="padding-top:0">
                <v-text-field
                v-model="verifyPassword"
                id="verifyPassword"
                :append-icon="showVerifyPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showVerifyPassword ? 'text' : 'password'"
                label="Repeat Password"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required, rules.passwordMatch]"
                @click:append="showVerifyPassword = !showVerifyPassword"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center" style="margin-top:0;padding-top:0">
            <v-col cols="10">
                <v-checkbox v-model="acceptedTerms" hide-details="" :rules="[rules.required]">
                    <template v-slot:label>
                        <div>
                            I agree with WhipRound 
                            <v-dialog 
                                v-model="showTerms"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition">
                                <template v-slot:activator="{}">
                                    <span class="text-decoration-underline anchor--text" @click="showTerms = true, acceptedTerms = !acceptedTerms">
                                        Terms and Conditions
                                    </span>
                                </template>
                                <v-card class="gradient-background">
                                    <v-toolbar
                                        dark
                                        color="transparent"
                                        elevation="0">
                                        <v-btn
                                            icon
                                            dark
                                            @click="showTerms = false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title class="headline-font centered-toolbar-title sub-title-font-size">Terms and Conditions</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <terms-and-conditions/>
                                </v-card>
                            </v-dialog>
                            and
                            <v-dialog 
                                v-model="showPolicy"
                                fullscreen
                                hide-overlay
                                transition="dialog-bottom-transition">
                                <template v-slot:activator="{}">
                                    <span  class="text-decoration-underline anchor--text" @click="showPolicy = true, acceptedTerms = !acceptedTerms">
                                        Privacy Policy
                                    </span>
                                </template>
                                <v-card class="gradient-background">
                                    <v-toolbar
                                        dark
                                        color="transparent"
                                        elevation="0">
                                        <v-btn
                                            icon
                                            dark
                                            @click="showPolicy = false">
                                            <v-icon>mdi-close</v-icon>
                                        </v-btn>
                                        <v-toolbar-title class="headline-font centered-toolbar-title sub-title-font-size">Privacy Policy</v-toolbar-title>
                                        <v-spacer></v-spacer>
                                    </v-toolbar>
                                    <privacy-policy/>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-checkbox>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
              <v-btn
                  class="text--secondary text-capitalize"
                  block
                  color="secondary"
                  depressed
                  large
                  rounded
                  @click="signUp()">
                sign up
                <v-icon
                    class="text--primary ml-2"
                    dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col class="d-flex align-center" cols="10" >
                <v-divider color="#19C7BB"></v-divider>
                <div color="accent" class="mx-5 accent--text">
                    OR
                </div>
                <v-divider color="#19C7BB"></v-divider>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
              <v-btn
                  class="text--secondary text-capitalize"
                  block
                  color="secondary"
                  depressed
                  large
                  rounded
                  @click="signIn()">
                sign in
                <v-icon
                    class="text--primary  ml-2"
                    dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>

<script>
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
import axios from 'axios'
import Vue from 'vue'
import TermsAndConditions from '../components/TermsAndConditions.vue'
import PrivacyPolicy  from '../components/PrivacyPolicy.vue'

export default {
    mixins: [loginRegisterMixin],
    components: {
        TermsAndConditions,
        PrivacyPolicy
    },
    data() {
        return {
            name: '',
            phone: '',
            email: '',
            password: '',
            verifyPassword: '',
            showPassword: false,
            showVerifyPassword: false,
            showTerms: false,
            showPolicy:false,
            acceptedTerms: false,
            acceptedpolicy: false,
            rules: {
                required: value => !!value || 'Required.',
                passwordMatch: value => value === this.password || 'Password not Match',
                minCharacters: value => value.length >= 8 || 'Password should be minimum of 8 characters',
                validEmail: value => (Vue.prototype.$emailRegex.test(value)) || ('enter a valid email'),
                phone: value => /^[+]?\d*$/.test(value) || 'insert valid phone nyumber',
            }
        };
    },
    created() {
        if(this.checkUserSignedIn())
            this.$router.push(Vue.prototype.$appRoutes.Home);
    },
    mounted() {
        this.$refs['name'].focus();
    },
    methods: {
        signUp() {
            if(!this.$refs.form.validate()) return;
            
            var signUpData = {
                name: this.name,
                email: this.email,
                phone: this.phone,
                password: this.password
            };
            axios.post(process.env.VUE_APP_API_ROOT + 'api/create_user', signUpData)
                .then((response) => {
                    this.requestAouthToken(this.email, this.password, this.loadUserData); // afetr success user creation request user token
                }, (error) => {
                    alert(this.errorHandler(error));
                });
        },
        loadUserData() {
            this.getUserData(this.onSuccessRegister);
        },
        onSuccessRegister() {
            this.$router.push(Vue.prototype.$appRoutes.EmailConfirmation);
        },
        signIn() {
            this.$router.push(Vue.prototype.$appRoutes.SignIn);
        },
        errorHandler(error) {
            var message = '';
            var errors = error.response.data.errors;
            if(errors.name != null)
                message += errors.name + '\n'
            if(errors.email != null)
                message += errors.email + '\n'
            if(errors.password != null)
                message += errors.password + '\n'
            if(errors.phone != null)
                message += errors.phone + '\n'
            
            return message;
        }
    }
}
</script>

import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vuetify.config.silent = true;

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark:true,
        themes:{
            dark: {
                primary: "#26889D",
                secondary: "#C63FE0",
                accent: "#19C7BB",
                accent2: "#6E3C8C",
                anchor:"#60DCFF",
                textDarkBg:"#FFFFFF",
                error:"#FF3C41s",
            },
        },
    },

});

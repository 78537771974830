import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import vuetify from './plugins/vuetify'
import VueSession from 'vue-session'

var sessionOptions = {
  persist: true
}
Vue.use(VueSession, sessionOptions)

Vue.prototype.$emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

Vue.prototype.$requestAouthData = {
  grant_type: 'password',
  client_id: 3,
  client_secret: 'aZ6XrTiXoz8Ce8AoERpSWKTpkdwuNmpifm9U4fG9',
};

Vue.prototype.$transactionType = {
  pay: 1,
  withdraw: 2
}

Vue.prototype.$transactionStatus = {
  pending: 1,
  approved: 2,
  rejected: 3
}

Vue.prototype.$userStatus = {
  new: 1,
  verified: 2,
  payed: 3
}

Vue.config.productionTip = false

Vue.prototype.$appRoutes = {
  Home: {
    name: 'Home'
  },
  SignIn: {
    name: 'SignIn'
  },
  SignUp: {
    name: 'SignUp'
  },
  ForgotPassword: (email) => {
    return {
      name: 'ForgotPassword',
      params: {
        email: email
      }
    }
  },
  ChangePassword: (email) => {
    return {
      name: 'ChangePassword',
      params: {
        email: email
      }
    }
  },
  EmailConfirmation: {
    name: 'EmailConfirmation'
  },
  CreateGroup: {
    name: 'CreateGroup'
  },
  UpdateGroup: (groupId) => {
    return {
      name: 'UpdateGroup',
      params: {
        groupId: groupId,
      }
    }
  },
  Group: {
    name: 'Group'
  },
  Payment: (groupID) => {
    return {
      name: 'Payment',
      params: {
        groupID: groupID
      }
    }
  },
  Withdraw: (groupID) => {
    return {
      name: 'Withdraw',
      params: {
        groupID: groupID
      }
    }
  },
  RegistrationFees: {
    name: 'RegistrationFees'
  },
  JoinGroup: (code) => {
    return {
      name: 'JoinGroup',
      params: {
        code: code
      }
    }
  },
  Members: {
    name: 'Members'
  },
  TermsAndConditions: {
    name: 'TermsAndConditions'
  },
  PrivacyPolicy: {
    name: 'PrivacyPolicy'
  },
  InstallVideo: {
    path: 'https://www.youtube.com/watch?v=DASaHIKKD2Q'
  },
  CatchAll: {
    name: 'CatchAll'
  },
}

Vue.mixin({
  methods: {
    checkUserSignedIn() {
      if (!this.$session.get('user')) {
        return false;
      }
      else return true;
    },
    getUserStatus() {
      var user = this.$session.get('user');
      if(user == null) {
        this.$router.push(Vue.prototype.$appRoutes.SignIn)
        return -1;
      }

      return user.status;
    },
    checkUserStatus(status) {
      if(status == Vue.prototype.$userStatus.new || status == 0) {
        this.$router.push(Vue.prototype.$appRoutes.EmailConfirmation);
      }
      
      else if(status == Vue.prototype.$userStatus.verified) {
        this.$router.push(Vue.prototype.$appRoutes.RegistrationFees)
      }
    },
    isPageAvailable() {
      if (!this.checkUserSignedIn())
        this.$router.push(Vue.prototype.$appRoutes.SignIn)
      else {
        var userStatus = this.getUserStatus();
        this.checkUserStatus(userStatus);
      }
    }
  }
})

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.$showInstallBTN = false;
const showInstallBTNEvent = new Event('ShowInstallBTN');

window.addEventListener('beforeinstallprompt', (event) => {
  // Prevent the mini-infobar from appearing on mobile
  event.preventDefault();
  // Stash the event so it can be triggered later.
  window.deferredPrompt = event;
  // Show the install button in dashboard
  window.dispatchEvent(showInstallBTNEvent);

  console.log('saved install event');
});
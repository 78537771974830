<template>
    <v-form
        ref="form"
        lazy-validation>
        <v-container class="gradient-background">
            <v-row justify="center">
                <v-col cols="2">
                    <router-link :to="{name: 'Group'}" class="text-decoration-none">
                        <v-icon
                            class="text--primary text-h4"
                            dark>
                            mdi-arrow-left
                        </v-icon>
                    </router-link>
                </v-col>
                <v-col cols="7">
                    <v-img
                        class="mt-8 mb-8"
                        src="../assets/logo.png"
                        width="100%"></v-img>
                </v-col>
                <v-col cols="2"/>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" align="center">
                    <div>
                        Payment Details
                    </div>
                </v-col>
            </v-row>
            <bank-info
                :editable="true"
                :showBank="true"
                @updateBank="updateBank"
                @updateAccountNumber="updateAccountNumber"
                @updateSortCode="updateSortCode"/>
            <v-row justify="center" style="margin-top:0">
                <v-col cols="10 pt-0">
                    <v-text-field
                        v-model="amount"
                        name="amount"
                        label="Amount"
                        filled
                        background-color="primary"
                        color="textDarkBg"
                        rounded
                        :rules="[rules.required, rules.minValue]"
                        type="number"
                        dense></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="secondary"
                        depressed
                        large
                        rounded
                        @click="payToGroup()"
                    >
                        Pay
                        <v-icon
                            class="text--primary  ml-2"
                            dark>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import BankInfo from '../components/BankInfo.vue'
import { faizPay } from '../mixins/FaizPayMixin.js'
export default {
    mixins:[faizPay],
    components: {
        BankInfo
    },
    props:['groupID'],
    data() {
        return {
            amount: null,
            bankID: '',
            accountNumber: '',
            sortCode: '',
            rules: {
                required: value => !!value || 'Required',
                minValue: value => value >= 0.5 || 'minimum 0.5'
            }
        }
    },
    created() {
        this.isPageAvailable();
        this.$session.set('returnPageAfterPay', 'Group');
        var userData = this.$session.get('user');
        this.bankID = userData.bank_id;
        this.accountNumber = userData.account_number;
        this.sortCode = userData.sort_code;
    },
    methods: {
        updateBank(bankID) {
            this.bankID = bankID;
        },
        updateAccountNumber(accountNumber) {
            this.accountNumber = accountNumber;
        },
        updateSortCode(sortCode) {
            this.sortCode = sortCode;
        },
        payToGroup(){
            if(this.$refs.form.validate()) {
                this.pay(parseFloat(this.amount).toFixed(2), this.bankID, this.accountNumber, this.sortCode, this.groupID);
            }
        },
    }
}
</script>

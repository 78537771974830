<template>
  <v-dialog v-model="dialog" persistent width="300">
    <v-card dark>
      <v-card-text>
        <v-col align="center">
          Please Wait...
        </v-col>
        <v-progress-linear
          indeterminate
          color="white"
          class="mb-0"
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data() {
        return {
            dialog: true,
        };
    },
}
</script>
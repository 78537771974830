<template>
        <v-container v-if="this.group != null" class="gradient-background">
            <v-row>
                <v-col cols="2">
                    <router-link :to="{ name: 'Home' }" class="text-decoration-none">
                        <v-icon
                            class="text--primary text-h4"
                            dark>
                            mdi-arrow-left
                        </v-icon>
                    </router-link>
                </v-col>
                <v-col cols="8" align="center" class="headline-font">
                    {{group.name}}
                </v-col>
                <v-col cols="2" align="end">
                    <v-icon @click="updateGroup">mdi-cog</v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <h1 style="font-size: 2.5em">£{{parseFloat(group.amount).toFixed(2)}}</h1>
            </v-row>
            <v-row>
                <v-col cols="4">
                    <router-link :to="{name: 'Payment', params: { groupID: group.id}}" style="text-decoration:none">
                        <v-card
                            class="circle-card-1 rounded-lg inner-gradient-background-1 pa-2 "
                            elevation="0">
                            <div class="text-center">
                                <v-icon>mdi-arrow-down</v-icon>
                            </div>
                            <div class="text-center font-weight-light  text-subtitle-2 text-no-wrap">
                                PAY
                            </div>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col cols="4" align="center">
                    <router-link :to="{name: 'Members'}" style="text-decoration:none">
                        <v-card
                            class="rounded-lg inner-gradient-background-2 pa-2"
                            elevation="0">
                            <div class="text-center">
                                <v-icon>mdi-account-multiple</v-icon>
                            </div>
                            <div class="text-center font-weight-light text-subtitle-2 text-no-wrap">
                                MEMBERS
                            </div>
                        </v-card>
                    </router-link>
                </v-col>
                <v-col cols="4" align="right">
                    <router-link :to="{name: 'Withdraw', params: { groupID: group.id}}" style="text-decoration:none">
                        <v-card
                            class="rounded-lg inner-gradient-background-3 pa-2"
                            elevation="0">
                            <div class="text-center">
                                <v-icon>mdi-call-split</v-icon>
                            </div>
                            <div class="text-center font-weight-light text-subtitle-2 text-no-wrap">
                                WITHDRAW
                            </div>
                        </v-card>
                    </router-link>
                </v-col>
            </v-row>
            <v-row align="end">
                <v-col class="title" cols="12">
                    <div class="headline-font title-font-size">Transactions</div>
                </v-col>
            </v-row>
            <v-row justify="center">
            <v-col cols="12">
                <v-list style="background-color: rgba(255, 255, 255, 0);overflow-y:auto;height:345px">
                    <template>
                        <transaction-card
                            v-for="(transaction, i) in group.transaction"
                            :key="i"
                            :transaction="transaction"
                            :class="(i == 0) ? 'rounded-t-lg' : (i == (group.transaction.length - 1) ? 'rounded-b-lg': '')"
                            style="background-color:#1E1E1E"
                            />
                    </template>
                </v-list>
            </v-col>
        </v-row>
        </v-container>
</template>

<script>
import Vue from 'vue';
import TransactionCard from '../components/TransactionCard.vue';
import { groupMixin } from '../mixins/GroupMixin.js'
export default {
    mixins:[groupMixin],
    components: {
        TransactionCard
    },
    created() {
        //this.isPageAvailable();
    },
    data() {
        return {
            group: this.$session.get('currentGroup') != null ? this.$session.get('currentGroup')[0] : null,
        };
    },
    methods: {
        updateGroup() {
            this.$router.push(Vue.prototype.$appRoutes.UpdateGroup(this.group.id));
        }
    }
}
</script>

<template>
    <v-list-item class="transaction-list-item" style="margin-bottom:2px;border:2px" >
          <v-list-item-icon>
              <v-icon
                  class="profile-icon mt-2"
                  color="accent2"
                  dark>
                  mdi-account-circle
              </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="member.name"></v-list-item-title>
            <v-list-item-subtitle class="text--lighten-1 grey--text small-font-size" v-text="member.phone"></v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
</template>

<script>
export default {
    props:['member'],
    data() {
        return {

        };
    },
    methods: {
        
    }
}
</script>

<template>
    <v-container class="gradient-background">
        <v-row justify="center">
            <v-col cols="2" />
            <v-col cols="7">
                <v-img
                    class="mt-8 mb-8"
                    src="../assets/logo.png"
                    width="100%"></v-img>
            </v-col>
            <v-col align="right" class="pa-2" cols="2">
            <router-link :to="{name: 'Profile'}">
                <v-img
                src="../assets/Icons/icon_account.png"
                width="24px"
                height="24px">
                </v-img>
            </router-link>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" align="center">
                <div>
                    Registration Fee <v-icon small>mdi-currency-gbp</v-icon>{{registrationFee}} = 6 months
                </div>
            </v-col>
        </v-row>
            <bank-info
            :editable="true"
            :showBank="true"
            @updateBank="updateBank"
            @updateAccountNumber="updateAccountNumber"
            @updateSortCode="updateSortCode" />
        
        <v-row justify="center">
            <v-col cols="10">
                <v-btn
                    class="text--secondary"
                    block
                    color="secondary"
                    depressed
                    large
                    rounded
                    @click="payRegistrationFees()"
                    >
                Pay Now
                <v-icon
                    class="text--primary  ml-2"
                    dark>
                mdi-arrow-right
                </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
                <v-btn
                    class="text--secondary"
                    block
                    color="error"
                    depressed
                    large
                    rounded
                    @click="later()"
                    >
                Pay Later
                <v-icon
                    class="text--primary ml-2"
                    dark>
                mdi-close
                </v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue'
import BankInfo from '../components/BankInfo.vue'
import { groupMixin } from '../mixins/GroupMixin.js'
import { faizPay } from '../mixins/FaizPayMixin.js'
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
    mixins:[faizPay, loginRegisterMixin, groupMixin],
    components: {
        BankInfo
    },
    data() {
        return {
            bankID: null,
            accountNumber: null,
            sortCode: null,
            registrationFee: '5.00',
        }
    },
    created() {
        if (!this.checkUserSignedIn())
            this.$router(Vue.prototype.$appRoutes.SignIn)
        else {
            this.getUserData();
            var userStatus = this.getUserStatus();
            if(userStatus != Vue.prototype.$userStatus.verified) //continue in this page id user status is verified
                this.$router.push(Vue.prototype.$appRoutes.Home)
            else {
                this.$session.set('returnPageAfterPay', 'Home');
                var userData = this.$session.get('user');
                this.bankID = userData.bank_id;
                this.accountNumber = userData.account_number;
                this.sortCode = userData.sort_code;
            }
        }
    },
    methods: {
        updateBank(bankID) {
            this.bankID = bankID;
        },
        updateAccountNumber(accountNumber) {
            this.accountNumber = accountNumber;
        },
        updateSortCode(sortCode) {
            this.sortCode = sortCode;
        },
        payRegistrationFees(){
            if(this.validateInputs()) {
                this.pay(this.registrationFee, this.bankID, this.accountNumber, this.sortCode, 'u');
                this.updateBankInfo();
            }
        },
        updateBankInfo() {
            this.saveBankInfo(this.bankID, this.accountNumber, this.sortCode);
            this.getUserData();
        },
        validateInputs() {
            if(this.bankID == null) {
                alert("Bank is required");
                return false;
            }
            else if(this.accountNumber == null) {
                alert("Account Number is required");
                return false;
            }
            else if(this.sortCode == null) {
                alert("Sort Code is required");
                return false;
            }
            else
                return true;
        },
        later() {
            var currentGroup = this.$session.get('currentGroup');
            if(currentGroup == null) {
                this.$router.push(Vue.prototype.$appRoutes.Home);
                return;
            }

            this.getGroupDetails(currentGroup[0].id, this.openGroup);
        }
    }
}
</script>

<template>
    <v-card class="rounded-lg" color="primary">
        <v-card-text>
            <v-file-input
                class="mt-2"
                v-model="selectedFile"
                accept="image/png, image/jpeg"
                label=" Select Group Image"
                placeholder="Select an Image"
                :show-size="1024"
                rounded
                dense
                background-color="primary"
                color="textDarkBg"
                @change="setupCropper"
                @click:clear="clearImage">
            </v-file-input>
            <v-row v-if="objectUrl || imgUrl">
                <v-col cols="12" sm="6" class="text-center">
                    <div class="overline">Original</div>
                    <div class="image-container elevation-4">
                        <img v-if="objectUrl" class="image-preview" ref="source" :src="objectUrl" />
                        <img v-else-if="imgUrl" class="image-preview" ref="source" :src="imgUrl" />
                    </div>
                    <div v-if="cropper" class="d-flex justify-center">
                        <v-btn x-large icon="icon" @click="resetCropper">
                            <v-icon>mdi-aspect-ratio</v-icon>
                        </v-btn>
                        <v-btn x-large icon="icon" @click="rotateLeft">
                            <v-icon>mdi-rotate-left</v-icon>
                        </v-btn>
                        <v-btn x-large icon="icon" @click="rotateRight">
                            <v-icon>mdi-rotate-right</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col v-if="previewCropped" class="text-center" cols="12" sm="6">
                    <div class="overline">Preview</div>
                    <div class="image-container elevation-4">
                        <img class="image-preview" :src="previewCropped" />
                    </div>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
import Cropper from 'cropperjs';
import debounce from 'lodash/debounce';
export default {
    props:['imgUrl'],
    data() {
        return {
            cropper: null,
            objectUrl: this.imgUrl,
            previewCropped: null,
            selectedFile: null,
            debouncedUpdatePreview: debounce(this.updatePreview, 257)
        }
    },
    mounted() {
        if(this.objectUrl != null) {
            this.StartCropperfromImgUrl();
        }
    },
    methods: {
        resetCropper () {
            this.cropper.reset()
        },
        rotateLeft () {
            this.cropper.rotate(-90)
        },
        rotateRight () {
            this.cropper.rotate(90)
        },
        setupCropper (selectedFile) {
            if (this.cropper) {
                this.cropper.destroy()
            }

            if (this.objectUrl) {
                window.URL.revokeObjectURL(this.objectUrl)
            }

            if (!selectedFile) {
                this.cropper = null
                this.objectUrl = null
                this.previewCropped = null
                return
            }

            this.objectUrl = window.URL.createObjectURL(selectedFile)
            this.$nextTick(this.setupCropperInstance)
        },
        setupCropperInstance () {
            this.cropper = new Cropper(this.$refs.source, {
                aspectRatio: 1,
                autoCropArea: 1,
                crop: this.debouncedUpdatePreview
            });
        },
        updatePreview (event) {
            const canvas = this.cropper.getCroppedCanvas()
            this.previewCropped = canvas.toDataURL('image/png')
            canvas.toBlob((blob) => {
                var file = new File([blob], "groupe_image.png",{type:"image/png", lastModified:new Date().getTime()})
                this.$emit('updateImage', file);
            })
        },
        clearImage (event) {
            this.$emit('updateImage', null);
        },
        async StartCropperfromImgUrl() {
            const response = await fetch(this.imgUrl);
            const blob = await response.blob();
            this.setupCropper(blob);
        }
    }
}
</script>

<style scoped>
    .image-container
    {
        display: inline-block
    }

    .image-preview
    {
        display: block;
        max-height: 229px;
        max-width: 100%
    }
</style>

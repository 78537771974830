<template>
    <v-card elevation="0" color="accent" @click="share" class="px-4 py-2 rounded-lg">

            <v-row style="margin:0;padding:0">
            <v-col cols="11" style="margin:0;padding:0">
            <div class="sub-title-font-size">Share Group Link</div>
            </v-col>
            <v-col style="margin:0;padding:0">
                <v-icon>mdi-link</v-icon>
            </v-col>
            </v-row>

    </v-card>
</template>

<script>
export default {
    props:['groupCode'],
    data() {
        return {

        };
    },
    methods: {
        share() {
            var shareData = {
                text: "You've been invited in to a group! Click this link to join!",
                title: 'Whipround',
                url: window.location.origin + '/joinGroup/' + this.groupCode,
            }
            navigator.share(shareData)
                .then(() => {
                    console.log('Successful share');
                })
                .catch((error) => {
                    console.log('Error sharing' + error);
                }
            );
        },
    }
}
</script>

<template>
    <v-container
        class="gradient-background">
    <v-form
        ref="form"
        lazy-validation>
        <v-row justify="center">
            <v-col cols="7">
                <v-img
                    class="mt-8 mb-8"
                    src="../assets/logo.png"
                    width="100%"></v-img>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
                <v-text-field
                ref="email"
                v-model="email"
                label="Email"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required, rules.validEmail]"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row justify="center" style="margin-bottom:0;padding-bottom:0">
            <v-col cols="10">
                <v-text-field
                ref="password"
                v-model="password"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                :type="showPassword ? 'text' : 'password'"
                label="Password"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required]"
                @click:append="showPassword = !showPassword"
                dense></v-text-field>
            </v-col>
        </v-row>
      <v-row justify="center" style="margin-top:0;padding-top:0">
        <v-col cols="10" style="margin-top:0;padding-top:0">
          <a class="font-weight-light text-decoration-underline" @click="forgotPassword()">Forgot your Password?</a>
        </v-col>
      </v-row>
        <v-row justify="center">
            <v-col cols="10">
              <v-btn
                  class="text--secondary text-capitalize"
                  block
                  color="secondary"
                  depressed
                  large
                  rounded
                  @click="signIn()">
                Sign In
                <v-icon
                  class="text--primary  ml-2"
                  dark>
                mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col class="d-flex align-center" cols="10" >
              <v-divider color="#19C7BB"></v-divider>
              <div color="accent" class="mx-5 accent--text">
                OR
              </div>
              <v-divider color="#19C7BB"></v-divider>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="10">
              <v-btn
                  block
                  class="text--secondary text-capitalize"
                  color="secondary"
                  depressed
                  large
                  rounded
                  @click="signUp()">
                Sign Up
                <v-icon
                    class="text--primary ml-2"
                    dark>
                  mdi-arrow-right
                </v-icon>
              </v-btn>
            </v-col>
        </v-row>
    </v-form>
    </v-container>
</template>

<script>
import Vue from 'vue';
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
    //mixin script holds all api requests for login and registrations functions
    mixins: [loginRegisterMixin],
    data() {
        return {
            email: '',
            password: '',
            showPassword: false,
            rules: {
                required: value => !!value || 'Required.',
                validEmail: value => (Vue.prototype.$emailRegex.test(value)) || ('enter a valid email'),
            }
        };
    },
    created() {
        if(this.checkUserSignedIn())
            this.$router.push(Vue.prototype.$appRoutes.Home);
    },
    mounted() {
      this.$refs['email'].focus();
    },
    methods: {
        signIn() {
            if(this.$refs.form.validate())
              this.requestAouthToken(this.email, this.password, this.loadUserData)
        },
        loadUserData() {
            this.getUserData(this.onSuccessSignIn);
        },
        onSuccessSignIn() {
            this.$router.push(Vue.prototype.$appRoutes.Home)
        },
        signUp() {
            this.$router.push(Vue.prototype.$appRoutes.SignUp);
        },
        forgotPassword() {
          if(this.email == "" ||
              this.email == null ||
              !Vue.prototype.$emailRegex.test(this.email)) {
            alert('Please enter a valid email');
            return;
          }
          this.$router.push(Vue.prototype.$appRoutes.ForgotPassword(this.email));
        },
    }
}
</script>

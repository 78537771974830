<template>
    <v-row>
        <v-col>
            <v-row justify="center" v-if="showBank" style="margin-top:0">
                <v-col cols="10" style="padding-top:0">
                    <v-select
                        :disabled="!editable"
                        v-model="bankID"
                        name="bankID"
                        :items="bankList"
                        item-text="name"
                        item-value="id"
                        label="Bank"
                        filled
                        background-color="primary"
                        color="textDarkBg"
                        rounded
                        :rules="[rules.required]"
                        dense
                        @change="$emit('updateBank', bankID)"></v-select>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin-top:0">
                <v-col cols="10" style="padding-top:0">
                    <v-text-field
                        :disabled="!editable"
                        v-model="accountNumber"
                        name="accountNumber"
                        label="Account Number"
                        filled
                        background-color="primary"
                        color="textDarkBg"
                        rounded
                        :rules="[ rules.length8, rules.onlyNumbers]"
                        type="number"
                        dense
                        @change="$emit('updateAccountNumber', accountNumber)"></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center" style="margin-top:0">
                <v-col cols="10" style="padding-top:0">
                    <v-text-field
                        :disabled="!editable"
                        v-model="sortCode"
                        name="sortCode"
                        label="Sort Code"
                        filled
                        background-color="primary"
                        color="textDarkBg"
                        rounded
                        :rules="[rules.required, rules.length6, rules.onlyNumbers]"
                        type="number"
                        dense
                        @change="$emit('updateSortCode', sortCode)"></v-text-field>
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { faizPay } from '../mixins/FaizPayMixin.js';

export default {
    props:['editable', 'showBank'],
    mixins:[faizPay],
    data() {
        return {
            bankID: '',
            accountNumber: '',
            sortCode: '',
            bankList: [],
            rules: {
                required: value => !!value || 'Required',
                length6: value => (!!value && value.length == 6) || 'must be 6 numbers',
                length8: value => (!!value && value.length == 8) || 'must be 8 numbers',
                onlyNumbers: value => /^\d*$/.test(value) || 'must be only numbers'

            }
        }
    },
    created() {
        this.getBankList(this.generateBankDDL);
        var userData = this.$session.get('user');
        if(userData != null){
            this.bankID = userData.bank_id;
            this.accountNumber = userData.account_number;
            this.sortCode = userData.sort_code;
        }
    },
    methods: {
        generateBankDDL() {
            this.bankList = this.$session.get('bankList');
        },
    }
}
</script>

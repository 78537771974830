<template>
    <v-form
        ref="form"
        lazy-validation>
        <v-container class="gradient-background">
            <v-row justify="center">
                <v-col cols="2">
                    <router-link :to="{name: prevRoute}" class="text-decoration-none">
                        <v-icon
                            class="text--primary text-h4"
                            dark>
                            mdi-arrow-left
                        </v-icon>
                    </router-link>
                </v-col>
                <v-col cols="7">
                    <v-img
                        class="mt-8 mb-8"
                        src="../assets/logo.png"
                        width="100%"></v-img>
                </v-col>
                <v-col cols="2"/>
            </v-row>
            <v-row justify="center">
                <v-col cols="12" align="center">
                    <div>
                        Withdraw Details
                    </div>
                </v-col>
            </v-row>
            <bank-info
                :editable="true"
                :show-bank="false"
                @updateAccountNumber="updateAccountNumber"
                @updateSortCode="updateSortCode"/>
            <v-row justify="center" style="margin-top:0">
                <v-col cols="10 pt-0">
                    <v-text-field
                        v-model="amount"
                        name="amount"
                        label="Amount"
                        filled
                        background-color="primary"
                        color="textDarkBg"
                        rounded
                        :rules="[rules.required, rules.minValue]"
                        type="number"
                        dense></v-text-field>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="secondary"
                        depressed
                        large
                        rounded
                        @click="withdrawFromGroup()"
                    >
                        withdraw
                        <v-icon
                            class="text--primary  ml-2"
                            dark>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                     Funds withdrawn will be shown as pending until cleared. Please allow for a maximum of 90 minutes.
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import BankInfo from '../components/BankInfo.vue'
import { faizPay } from '../mixins/FaizPayMixin.js'
import { groupMixin } from '../mixins/GroupMixin.js'

export default {
    mixins:[faizPay, groupMixin],
    components: {
        BankInfo
    },
    props:['groupID'],
    data() {
        return {
            amount: null,
            bankID: '',
            accountNumber: '',
            sortCode: '',
            prevRoute: this.$session.flash.get('prevRoute'),
            rules: {
                required: value => !!value || 'Required',
                minValue: value => value >= 0.5 || 'minimum 0.5'
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(vm.prevRoute == null) {
                vm.prevRoute = from.name
                vm.$session.flash.set('prevRoute', from.name);
            }
        })
    },
    created() {
        this.isPageAvailable();
        var userData = this.$session.get('user');
        this.bankID = userData.bank_id;
        this.accountNumber = userData.account_number;
        this.sortCode = userData.sort_code;
    },
    methods: {
        updateAccountNumber(accountNumber) {
            this.accountNumber = accountNumber;
        },
        updateSortCode(sortCode) {
            this.sortCode = sortCode;
        },
        withdrawFromGroup(){
            if(this.validateInputs()) {
                this.getWithdrawToken(parseFloat(this.amount).toFixed(2), this.accountNumber, this.sortCode, this.groupID, this.submitWithdraw);
            }
        },
        createWithdraw(token) {
            this.createWithdrawRequest(token, this.submitWithdraw);
        },
        submitWithdraw(token) {
            this.submitWithdrawRequest(token, this.groupID, parseFloat(this.amount).toFixed(2), this.updateAndGotoGroup);
        },
        updateAndGotoGroup() {
            this.getGroupDetails(this.groupID, this.openGroup);
        },
        validateInputs() {
            if(!this.$refs.form.validate()) return false;
            //check if withdraw amount is equal or less than group amount
            var groupAmount = this.$session.get('currentGroup')[0].amount;
            if(this.amount > groupAmount) {
                alert('There are not enough funds available in the pot, please top up and try again');
                return false;
            }
            else
                return true;
        }
    }
}
</script>

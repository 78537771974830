<template>
    <v-container class="gradient-background">
        <v-row>
            <v-col cols="2">
                <router-link :to="{ name: 'Group' }" class="text-decoration-none">
                    <v-icon
                        class="text--primary text-h4"
                        dark>
                        mdi-arrow-left
                    </v-icon>
                </router-link>
            </v-col>
            <v-col cols="1" />
            <v-col class="headline-font" align="center" cols="6">
                Members
            </v-col>
            <v-col cols="3" />
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <share-button :group-code="group.code" />
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12">
                <v-list style="background-color: rgba(255, 255, 255, 0);">
                    <template>
                        <member-card
                            v-for="(member,i) in group.user"
                            :key="i" 
                            :member="member"
                            :class="(i == 0) ? 'rounded-t-lg' : (i == (group.user.length - 1) ? 'rounded-b-lg': '')"
                            style="background-color:#1E1E1E"
                            />
                    </template>
                </v-list>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import Vue from 'vue';
import ShareButton from '../components/ShareButton.vue'
import MemberCard from '../components/MemberCard.vue'
export default {
  components: {
      ShareButton,
      MemberCard,
    },
    data() {
        return {
            group: this.$session.get('currentGroup')[0]
        };
    },
    created() {
        //this.isPageAvailable();
    },
    methods: {

    }
}
</script>

<template>
    <v-container></v-container>
</template>

<script>
import Vue from 'vue';
import { groupMixin } from '../mixins/GroupMixin.js'
export default {
    mixins: [groupMixin],
    props:['code'],
    data() {
        return {

        };
    },
    created() {
        //if user not signed in save the group code in the session and redirect the user to sign in,
        //else join the group and open the group on success
        if (!this.checkUserSignedIn()) {
            this.$session.flash.set('joinGroupCode', this.code);
            this.$router.push(Vue.prototype.$appRoutes.SignIn);
        }
        else
            this.joinGroup(this.code, this.onJoinGroupSuccess)
    },
    methods: {
        onJoinGroupSuccess(groupID) {
            this.getGroupDetails(groupID, this.openGroup);
        }
    }
}
</script>
<template>
    <v-container class="gradient-background">
        <v-row>
            <v-col cols="2">
                <v-icon
                    class="text--primary text-h4"
                    dark
                    @click="checkChanges">
                    mdi-arrow-left
                </v-icon>
            </v-col>
            <v-col cols="8"  align="center">
                <span class="headline-font">Update Group</span>
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <v-row justify="center">
            <v-col style="margin-bottom:0px;padding-bottom:5px;">
                <v-text-field
                v-model="group.name"
                label="Group Name"
                filled
                background-color="primary"
                color="textDarkBg"
                rounded
                :rules="[rules.required, rules.minCharacters]"
                dense></v-text-field>
            </v-col>
        </v-row>
        <v-row class="mt-0">
            <v-col>
                <image-cropper
                    :imgUrl="this.baseImageLink + group.image.slice(1)"
                    @updateImage="updateImage"/>
            </v-col>
        </v-row>
        <v-row align="end" >
            <v-col>
                <v-btn
                    class="text--secondary text-capitalize"
                    block
                    color="secondary"
                    depressed
                    large
                    rounded
                    @click="update">
                    Update
                    <v-icon
                        class="text--primary ml-2"
                        dark>
                        mdi-arrow-right
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <router-link :to="{name: 'Group'}" style="text-decoration:none">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="error"
                        depressed
                        large
                        rounded>
                        Cancel
                        <v-icon
                            class="text--primary ml-2"
                            dark>
                            mdi-close
                        </v-icon>
                    </v-btn>
                </router-link>
                <custom-alert ref="groupChangedDialog" title="Changes Not Saved" message="You have unsaved changes. Are you sure you want to leave without saving?"
                                  :confirm="true" @onConfirm="goToPreviousPage()"/>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <a :href="`mailto:email@whiproundinfo.com?subject=Request to Leave Group
                            &body=Hi Whipround,
                            %0D%0A I\'d like to leave this group,%0D%0A
                            %0D%0A Group Name: ${group.name}
                            %0D%0A Group ID: ${group.id}%0D%0A
                            %0D%0A My User Name: ${user.name}
                            %0D%0A My User Email: ${user.email}
                            %0D%0A My User ID: ${user.id}%0D%0A
                            %0D%0A Note from Whipround:
                            %0D%0A We will delete you out of the required group within 2 working days.
                            %0D%0A If you have any further queries, please contact us on email@whiproundinfo.com%0D%0A
                            %0D%0A Thanks,
                            %0D%0A WhipRound team`"
                   class="font-weight-light text-decoration-none">
                    <v-icon dense color="anchor" class="mr-1">
                        mdi-logout
                    </v-icon>
                    <span class="text-decoration-underline">Request Leave Group</span>
                    </a>
            </v-col>
        </v-row>
        <v-row class="my-0">
            <v-col>
                <a :href="`mailto:email@whiproundinfo.com?subject=Request to Delete Group - #${group.id}
                            &body=Hi Whipround,
                            %0D%0A%0D%0A I\'d like to delete this group,%0D%0A
                            %0D%0A Group Name: ${group.name}
                            %0D%0A Group ID: ${group.id}%0D%0A
                            %0D%0A Note from Whipround:
                            %0D%0A We will delete the required group within 2 working days.
                            %0D%0A If you have any further queries, please contact us on email@whiproundinfo.com%0D%0A
                            %0D%0A Thanks,
                            %0D%0A WhipRound team`"
                   class="font-weight-light text-decoration-none">
                    <v-icon dense color="anchor" class="mr-1">
                        mdi-delete-outline
                    </v-icon>
                    <span class="text-decoration-underline">Request Delete Group</span>
                    </a>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { groupMixin } from '../mixins/GroupMixin.js';
import Vue from 'vue';
import CustomAlert from '../components/CustomAlert.vue'
import ImageCropper from '../components/ImageCropper.vue';

export default {
    mixins: [groupMixin],
    components: {
        ImageCropper, CustomAlert
    },
    props:['groupId'],
    data() {
        return {
            user: this.$session.get('user'),
            group: this.$session.get('currentGroup')[0],
            baseImageLink: process.env.VUE_APP_API_ROOT,
            groupImage: null,
            updatedGroupImage: null,
            originalGroupName: this.$session.get('currentGroup')[0].name,
            rules: {
                required: value => !!value || 'Required.',
                minCharacters: value => value.length >= 3 || 'name should be minimum of 5 characters'
            }
        };
    },
    created() {
        //this.isPageAvailable();
    },
    methods: {
        update() {
            this.updateGroup(this.group.name, this.groupId, this.updatedGroupImage, this.getGroup)
        },
        getGroup() {
            this.getGroupDetails(this.groupId, this.openGroup)
        },
        updateImage(file) {
            if(this.groupImage == null && this.updatedGroupImage == null && this.group.image != null) {
                this.groupImage = file;
                this.updatedGroupImage = file;
            }
            else
                this.updatedGroupImage = file;
        },
        checkChanges() {
            if(this.groupImage == this.updatedGroupImage && this.group.name == this.originalGroupName)
                this.goToPreviousPage();
            else
                this.$refs.groupChangedDialog.show();
        },
        goToPreviousPage() {
            this.$router.go(-1);
        }
    }
}
</script>

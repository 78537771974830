<template>
  <v-container class="gradient-background" >
    <v-row>
        <v-col cols="2">
            <v-img width="40" src="../assets/Digiipot-icon.png"></v-img>
        </v-col>
        <v-col cols="8">
          <h2 class="text-center headline-font title-small-font-size font-weight-regular">Welcome {{userData.name}}</h2>
        </v-col>
        <v-col cols="2" align="right">
          <router-link :to="{name: 'Profile'}"
                       class="text-decoration-none">
              <v-icon
                  class="profile-icon mt-2"
                  color="accent"
                  dark>
                  mdi-account-circle
              </v-icon>
          </router-link>
        </v-col>
    </v-row>
    <v-row>
      <v-slide-group>
        <v-slide-item>
          <home-card 
            :url="{ name: 'CreateGroup' }"
            :title="'Create Group'"
            :details="'& Invite friends to use Digiipot'"
            :background-img="'BTN_create_group.png'"
            :icon-img="'icon_create_group.png'"
            style="margin:10px"/>
        </v-slide-item>
        <v-slide-item>
            <home-btn-card
              v-if="currentOS == 'android' && showInstallBTN"
              :title="'Install App'"
              :details="'on your device homescreen'"
              :backgroundImg="'BTN_install.png'"
              :icon-img="'icon_install.png'"
              style="margin:10px" 
              :handler="installAPP" />
        </v-slide-item>
        <v-slide-item>
          <home-btn-card
              v-if="currentOS == 'ios'"
              :title="'Video Instructions'"
              :details="'to install Digiipot on your device'"
              :backgroundImg="'BTN_install.png'"
              :icon-img="'icon_video.png'"
              class="multiline-title-card"
              style="margin:10px" 
              :handler="openVideoInstructions" />
        </v-slide-item>
          <v-slide-item v-if="updateExists">
              <home-btn-card
                  :title="'Update Now'"
                  :details="'new version of app available'"
                  :backgroundImg="'BTN_recent_pot.png'"
                  :icon-img="'icon_update.png'"
                  class="multiline-title-card"
                  style="margin:10px"
                  :handler="forceRefreshApp" />
          </v-slide-item>
      </v-slide-group>
    </v-row>
    <v-row>
      <v-col>
        <h2 class="headline-font title-font-size font-weight-regular">My Groups</h2>
        <span>{{userGroups.length}} Groups</span>
      </v-col>
    </v-row>
    <v-row>
      <v-item-group v-model="selectedGroup" style="width:96%">
        <v-row>
          <v-col
            v-for="(group, i) in userGroups"
            :key="i"
            cols="6">
              <v-item style="margin:5px 10px 15px 25px;">
                <div>
                <v-row>
                  <v-img
                    width="163px"
                    height="132px"
                    class="rounded-lg"
                    :src="getGroupImage(group.image)"
                    lazy-src="../assets/loadingImg.png"
                    @click="getGroup(group.id)"></v-img>
                </v-row>
                <v-row>
                  <h4 @click="getGroup(group.id)" class="font-weight-regular mt-1">{{group.name}}</h4>
                </v-row>
                </div>
              </v-item>
          </v-col>
        </v-row>
      </v-item-group>
    </v-row>
  </v-container>
</template>

<script>
  import Vue from 'vue'
  import { groupMixin } from '../mixins/GroupMixin.js'
  import HomeCard from '../components/HomeCard.vue'
  import HomeBtnCard from '../components/HomeBTNCard.vue'
  import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'

  export default {
    mixins: [groupMixin, loginRegisterMixin],
    components: {
      HomeCard,
      HomeBtnCard,
    },
    data() {
      return {
        showInstallBTN: false,
        userData: null,
        baseImageLink: process.env.VUE_APP_API_ROOT,
        selectedGroup: null,
        userGroups: [],
        currentOS: null,
        updateExists: false,
      }
    },
    created() {
      if (this.checkUserSignedIn()) {
        this.getUserData();
        this.userData = this.$session.get('user');
        var userStatus = this.getUserStatus();
        if(userStatus == Vue.prototype.$userStatus.new || userStatus == 0) {
          this.$router.push(Vue.prototype.$appRoutes.EmailConfirmation)
          return;
        }

        //check if the user clicked on group link and has a group code then join the group
        var joinGroupCode = this.$session.flash.get('joinGroupCode');
        if (joinGroupCode != null)
          this.joinGroup(joinGroupCode, this.getGroup);
        else
          this.getUserGroups(this.updateUserGroupsonView);
      }
      else
        this.$router.push(Vue.prototype.$appRoutes.SignIn)

      window.addEventListener('ShowInstallBTN', (event) => {
        this.showInstallBTN = true;
      });

      if(window.deferredPrompt != null)
        this.showInstallBTN = true;

      if(navigator.appVersion.toLowerCase().indexOf('iphone') != -1)
        this.currentOS = 'ios';
      else
        this.currentOS = 'android';

     document.addEventListener('swUpdated', this.updateAvailable, { once: true });
    },
    methods: {
        updateAvailable() {
            this.updateExists = true;
        },
      getGroup(groupID) {
        this.getGroupDetails(groupID, this.openGroup);
      },
      updateUserGroupsonView() {
        this.userGroups = Vue.prototype.$userGroups;
      },
      getGroupImage(imageLink) {
        if(imageLink == '' || imageLink == null) return require('../assets/Buttons/BTN_recent_pot.png')
        else return this.baseImageLink + imageLink.slice(1);
      },
      async installAPP() {
        // Show the install prompt
        window.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        const { outcome } = await window.deferredPrompt.userChoice;
        // Hide the Install btn
        //this.showInstallBTN = false;

        console.log('User response to the install prompt: ' + outcome);
      },
      openVideoInstructions() {
        window.open(Vue.prototype.$appRoutes.InstallVideo.path, '_blank');
      },
      forceRefreshApp(){
          window.location.reload(true)

      },
    }
  }
</script>

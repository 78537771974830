<template>
    <v-container class="gradient-background">
        <v-row justify="center">
            <v-col cols="7">
                <v-img
                    class="mt-8 mb-8"
                    src="../assets/logo.png"
                    width="100%"></v-img>
            </v-col>
        </v-row>
        <div>
            <v-row justify="center">
                <v-col align="center">
                    <div class="title-font-size" v-if="status=='executed'">Thank You</div>
                    <div class="title-font-size" v-else-if="status=='pending'">Processing Transaction</div>
                    <div class="title-font-size" v-else>Transaction Cancelled</div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col align="center">
                    <v-icon class="huge-title-font-size" v-if="status=='executed'" color="success">mdi-checkbox-marked-circle</v-icon>
                    <v-icon class="huge-title-font-size"  v-else-if="status=='pending'" color="accent">mdi-cached</v-icon>
                    <v-icon class="huge-title-font-size"  v-else color="error">mdi-close-circle</v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col align="center">
                    <div class="normal-font sub-title-font-size" v-if="status=='executed'">Your payment was successful </div>
                    <div class="normal-font sub-title-font-size mb-3" v-else-if="status=='pending'">
                        <div>Your transaction is being processed</div>
                        <div>Please check your pot in a couple minutes</div>
                    </div>
                    <div class="normal-font sub-title-font-size" v-else>Payment Failed </div>
                    <div class="title-font-size anchor--text" >Order: #{{order}}</div>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col align="center">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="secondary"
                        depressed
                        large
                        rounded
                        @click="UpdateUserData">
                        Continue
                        </v-btn>
                </v-col>
            </v-row>
        </div>
    </v-container>
</template>

<script>
import Vue from 'vue'
import { groupMixin } from '../mixins/GroupMixin.js'
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
    mixins:[loginRegisterMixin, groupMixin],
    data() {
        return {
            order: this.$route.query.order,
            status: this.$route.query.status,
            prevRoute: this.$session.get('returnPageAfterPay')
        }
    },
    methods: {
        UpdateUserData() {
            var currentGroup = this.$session.get('currentGroup');
            if(currentGroup == null) {
                this.getUserData(this.goToPreviousPage);
                return;
            }

            this.getGroupDetails(currentGroup[0].id, this.openGroup);
        },
        goToPreviousPage() {
            this.$router.push(Vue.prototype.$appRoutes.Home);
        }
    }
}
</script>

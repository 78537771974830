<template>
    <v-list-item class="transaction-list-item" style="margin-bottom:2px;border:2px" >
          <v-list-item-icon>
              <v-icon
                  class="profile-icon mt-2"
                  color="accent2"
                  dark>
                  mdi-account-circle
              </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title class="sub-title-font-size" v-text="transaction.user.name"></v-list-item-title>
            <v-list-item-subtitle class="text--lighten-1 grey--text" v-text="formatDate(transaction.updated_at)"></v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
              <div :style="{color: (getTransactionType(transaction.type) == '+') ? '#00D090' : 'white'}">
                <span style="font-weight:bold">{{getTransactionType(transaction.type)}}</span>
                <span style="font-weight:bold">£{{transaction.amount}}</span>
              </div>
              <div class="small-font-size text--lighten-1 headline-font grey--text">
                  {{getTransactionStatus(transaction.status_id)}}
              </div>
          </v-list-item-action>
        </v-list-item>
</template>

<script>
import Vue from 'vue';
export default {
    props:['transaction'],
    data() {
        return {

        };
    },
    methods: {
        formatDate(date) {
            const d = new Date(date);
            const dateFormated = d.getDate() + ' ' + d.toLocaleString('default', { month: 'long' }) + ' @ ' + d.toLocaleTimeString('en-Uk', {timeZone: 'Europe/London'})
            return dateFormated;
        },
        getTransactionType(type) {
            if(type == Vue.prototype.$transactionType.pay) return '+';
            else return '-';
        },
        getTransactionStatus(status) {
            if(status == Vue.prototype.$transactionStatus.pending) return 'Pending'
            else if(status == Vue.prototype.$transactionStatus.approved) return 'Approved'
            else return 'Rejected'
        }
    }
}
</script>

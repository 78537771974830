<template>
    <v-form
        ref="form"
        lazy-validation>
        <v-container  class="gradient-background">
            <v-row>
                <v-col cols="2">
                    <v-icon
                        class="text--primary text-h4"
                        @click="checkForUpdatedInfo"
                        dark>
                        mdi-arrow-left
                    </v-icon>
                </v-col>
                <v-col cols="8" align="center" class="headline-font">
                    <span>My Profile</span>
                </v-col>
                <v-col cols="2"></v-col>
            </v-row>
            <bank-info class="mt-6"
                       :editable="true"
                       :showBank="true"
                       @updateBank="updateBank"
                       @updateAccountNumber="updateAccountNumber"
                       @updateSortCode="updateSortCode"/>
            <v-row justify="center">
                <v-col cols="10">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="secondary"
                        depressed
                        rounded
                        large
                        @click="updateBankInfo"
                    >
                        Update Bank Info
                    </v-btn>
                    <custom-alert ref="bankInfoDialog" title="Bank Info Not Saved" message="You have unsaved changes. Are you sure you want to leave without saving?"
                                  :confirm="true" @onConfirm="goToPreviousPage()"/>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="error"
                        depressed
                        rounded
                        large
                        @click="showDialog"
                    >
                        Sign Out
                    </v-btn>
                    <custom-alert ref="signOutDialog" title="Sign Out" message="Are you sure you want to sign out?"
                                  :confirm="true" @onConfirm="signOut()"/>
                </v-col>
            </v-row>
            <v-row justify="center" class="mt-6">
                <v-col cols="10" class="justify-center d-flex">
                    <a href="mailto:email@whiproundinfo.com">Contact Us</a>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10" class="justify-center d-flex">
                    <router-link :to="{name: 'TermsAndConditions'}">
                        Terms and Conditions
                    </router-link>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10" class="justify-center d-flex">
                    <router-link :to="{name: 'PrivacyPolicy'}">
                        Privacy Policy
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import Vue from 'vue';
import BankInfo from '../components/BankInfo.vue'
import CustomAlert from '../components/CustomAlert.vue'
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
    mixins: [loginRegisterMixin],
    components: {
        BankInfo,
        CustomAlert
    },
    data() {
        return {
            user: this.$session.get('user'),
            bankID: '',
            accountNumber: '',
            sortCode: '',
            updatedBankID: '',
            updatedAccountNumber: '',
            updatedSortCode: '',
        };
    },
    created() {
        if(!this.checkUserSignedIn())
            this.$router.push(Vue.prototype.$appRoutes.SignIn)
        else {
            var userData = this.$session.get('user');
            this.bankID = this.updatedBankID = userData.bank_id;
            this.accountNumber = this.updatedAccountNumber = userData.account_number;
            this.sortCode = this.updatedSortCode = userData.sort_code;
        }
    },
    methods: {
        signOut() {
            this.$session.clear();
            this.$session.destroy();
            this.$router.push(Vue.prototype.$appRoutes.SignIn);
        },
        updateBank(bankID) {
            this.updatedBankID = bankID;
        },
        updateAccountNumber(accountNumber) {
            this.updatedAccountNumber = accountNumber;
        },
        updateSortCode(sortCode) {
            this.updatedSortCode = sortCode;
        },
        updateBankInfo() {
            if(this.$refs.form.validate()) {
                this.saveBankInfo(this.updatedBankID, this.updatedAccountNumber, this.updatedSortCode);
                this.getUserData();
                this.bankID = this.updatedBankID;
                this.accountNumber = this.updatedAccountNumber;
                this.sortCode = this.updatedSortCode;
            }
        },
        showDialog() {
            this.$refs.signOutDialog.show();
        },
        checkForUpdatedInfo() {
            if(this.bankID == this.updatedBankID
                && this.accountNumber == this.updatedAccountNumber
                && this.sortCode == this.updatedSortCode) {
                    this.goToPreviousPage()
                }
            else
                this.$refs.bankInfoDialog.show();
        },
        goToPreviousPage() {
            this.$router.go(-1);
        }
    }
}
</script>

<template>
    <v-form
        ref="form"
        lazy-validation>
        <v-container class="gradient-background">
            <v-row>
                <v-col cols="2">
                    <router-link :to="{ name: 'Home' }" class="text-decoration-none">
                        <v-icon
                            class="text--primary text-h4"
                            dark>
                            mdi-arrow-left
                        </v-icon>
                    </router-link>
                </v-col>
                <v-col cols="8" align="center" class="headline-font">
                    Create New Group
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col style="margin-bottom:0px;padding-bottom:5px;">
                    <v-text-field
                    v-model="groupName"
                    label="Group Name"
                    filled
                    background-color="primary"
                    color="textDarkBg"
                    rounded
                    :rules="[rules.required, rules.minCharacters]"
                    dense></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <image-cropper @updateImage="updateImage"/>
                </v-col>
            </v-row>

            <v-row>
                <v-col style="padding:10px">
                    <v-btn
                        class="text--secondary text-capitalize"
                        block
                        color="secondary"
                        depressed
                        large
                        rounded
                        @click="create">
                        Create
                        <v-icon
                            class="text--primary ml-2"
                            dark>
                            mdi-arrow-right
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
            <v-row align="end" >
                <v-col style="padding:10px">
                    <router-link :to="{name: 'Home'}" style="text-decoration:none">
                        <v-btn
                            class="text--secondary text-capitalize"
                            block
                            color="error"
                            depressed
                            large
                            rounded>
                            Cancel
                            <v-icon
                                class="text--primary ml-2"
                                dark>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </router-link>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import { groupMixin } from '../mixins/GroupMixin.js';
import Vue from 'vue';
import ImageCropper from '../components/ImageCropper.vue';

export default {
    mixins: [groupMixin],
    components: {
        ImageCropper
    },
    data() {
        return {
            groupName: '',
            groupImage: null,
            selectedContacts: null,
            contacts: {},
            rules: {
                required: value => !!value || 'Required.',
                minCharacters: value => value.length >= 5 || 'name should be minimum of 5 characters'
            }
        };
    },
    created() {
        //this.isPageAvailable();
    },
    methods: {
        create() {
            if(this.$refs.form.validate())
                this.createGroup(this.groupName, this.groupImage, this.getGroup)
        },
        getGroup() {
            this.getGroupDetails(Vue.prototype.$createdGroupID, this.goToMembersPage)
        },
        goToMembersPage() {
            this.$router.push(Vue.prototype.$appRoutes.Members);
        },
        updateImage(file) {
            this.groupImage = file;
        }
    }
}
</script>

<template>
  <v-app>
    <router-view/>
    <loading-overlay v-if="showLoadingOverlay"/>
  </v-app>
</template>
<style>
    @import './styles/styles.css';
</style>
<script>
import axios from 'axios'
import LoadingOverlay from './components/LoadingOverlay.vue'
import Vue from 'vue';
export default {
  components: {
    LoadingOverlay
  },
  data() {
    return {
      showLoadingOverlay: false,
    }
  },
  created() {
    //fix the vuetify issue of white background when extra scrolling
    let elHtml = document.getElementsByTagName('html')[0]
    elHtml.style.backgroundColor = "#121212";
    //setting default call before any axios request
    //any default behavior before any api call should be added here
    axios.interceptors.request.use(
      (config) => {
        this.showLoadingOverlay = true; //show the loading screen before any api call
        //add the Bearer token to the header if exist
        if(this.$session.get('token') != null)
          config.headers.Authorization = 'Bearer ' + this.$session.get('token')
        return config;
      },
      (error) => {
        this.showLoadingOverlay = false;
        alert(error.response.data.message)
        return Promise.reject(error);
      }
    );

    //setting default call after any axios request
    //any default behavior after any api call should be added here
    axios.interceptors.response.use(
      (response) => {
        this.showLoadingOverlay = false; //hide the loading screen after any api call
        return response;
      },
      (error) => {
        this.showLoadingOverlay = false;
        //alert(error.response.data.message);
        return Promise.reject(error);
      }
    );
  }
};
</script>

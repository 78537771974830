<template>
    <v-dialog
        v-model="showDialog"
        persistent
    >
        <v-card>
            <v-card-title class="text-h5" style="word-break: break-word;">
                {{title}}
            </v-card-title>
            <v-card-text style="word-break: break-word;">
                <span style="word-break:break-word">{{message}}</span>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="showDialog = false; $emit('onClose')"
                >
                    {{confirm ? 'No' : 'Ok'}}
                </v-btn>
                <v-btn
                    v-if="confirm"
                    color="green darken-1"
                    text
                    @click="showDialog = false; $emit('onConfirm')"
                >
                    Yes
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    props: ['title','message','confirm', ],
    data() {
        return {
            showDialog: false,
        }
    },
    methods: {
        show() {
            this.showDialog = true;
        }
    }
}
</script>
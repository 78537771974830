import axios from "axios";
import Vue from 'vue';
export const loginRegisterMixin = {
    methods: {
        requestAouthToken(email, password, onSuccess) {
            //add the email and password to the request aouth data
            Vue.prototype.$requestAouthData.username = email;
            Vue.prototype.$requestAouthData.password = password;
          
            axios.post(process.env.VUE_APP_API_ROOT + 'api/oauth/token', Vue.prototype.$requestAouthData)
                .then((response) => {
                    this.$session.start();
                    this.$session.set('token',response.data.access_token);
                    if (onSuccess != null)
                        onSuccess();
                }, (error) => {
                    if(error.response.status == 400)
                        alert('Invalid email or password')
                    else
                        alert(error.response.data.message);
                })
        },
        getUserData(onSuccess) {
            axios.get(process.env.VUE_APP_API_ROOT + 'api/user')
                .then((response) => {
                    this.$session.set('user', response.data);
                    if (onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        forgotPassword(email, onSuccess) {
            axios.post(process.env.VUE_APP_API_ROOT + 'api/forgot_password?email=' + email)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess(response.data);
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        verifyForgotCode(email, code, onSuccess) {
            axios.post(process.env.VUE_APP_API_ROOT + 'api/verify_forgot_code?verification_code=' + code + '&email=' + email)
                .then((response) => {
                    if(response.data == false) {
                        alert('Wrong code.\nPlease make sure you entered the correct email and check your inbox for Whipround email with verification code');
                        return;
                    }
                    else {
                        if(onSuccess != null)
                        onSuccess();
                    }
                }, (error) => {
                    alert(error.message);
                })
        },
        updatePassword(email, password, onSuccess) {
            var data = {
                email: email,
                password: password
            }
            axios.post(process.env.VUE_APP_API_ROOT + 'api/update_password', data)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        verifyEmail(code, onSuccess) {
            var data = {
                verification_code: code
            }
            axios.post(process.env.VUE_APP_API_ROOT + 'api/verify_email', data)
                .then((response) => {
                    if(response.data == false) {
                        alert('Wrong code.\n Please make sure you typed the email correct, and check your inbox for the verification code.');
                    }
                    else {
                        if(onSuccess != null)
                            onSuccess();
                    }
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        updateEmail(email, onSuccess) {
            axios.post(process.env.VUE_APP_API_ROOT + 'api/update_email?new_email=' + email)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess(response.data);
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        resendVerificationCode(email, onSuccess) {
            axios.post(process.env.VUE_APP_API_ROOT + 'api/resend_code?email=' + email)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        saveBankInfo(bankID, accountNumber, sortCode, onSuccess) {
            var data = {
                bank_id: bankID,
                account_number: accountNumber,
                sort_code: sortCode
            }

            axios.post(process.env.VUE_APP_API_ROOT + 'api/save_bank_info', data)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        }
      },
}
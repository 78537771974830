<template>
  <v-container class="gradient-background">
    <v-row justify="center">
      <v-col cols="2" />
      <v-col cols="7">
        <v-img
            class="mt-8 mb-8"
            src="../assets/logo.png"
            width="100%"></v-img>
      </v-col>
      <v-col align="right" class="pa-2" cols="2">
          <router-link :to="{name: 'Profile'}">
            <v-img
              src="../assets/Icons/icon_account.png"
              width="24px"
              height="24px">
            </v-img>
          </router-link>
        </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12">
        <v-window
            v-model="window">
          <v-window-item>
            <v-row justify="center">
              <v-col cols="10">
                <div class="normal-font text-h6 text--secondary font-weight-light"> {{ email }}</div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <div class="normal-font">A verification code was sent to the above email, please enter the code to complete the registration
                </div>

              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <div class="normal-font text-body-2 text--disabled font-weight-light">
                  Not the correct Email?
                  <v-btn
                      @click="changeEmail()"
                      :disabled="timerCount>0"
                      color="anchor"
                      plain
                      class="text-body-2 pa-0 font-weight-light text-decoration-underline text-capitalize"
                  >
                    Change Email {{ timerCount > 0 ? "(" + timerCount + ")" : "" }}
                  </v-btn>
                </div>

              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-text-field
                    v-model="verificationCode"
                    label="Verification Code"
                    filled
                    background-color="primary"
                    color="textDarkBg"
                    rounded
                    dense
                    :rules="[rules.required]"></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn
                    @click="resendCode()"
                    :disabled="timerCount>0"
                    color="anchor"
                    plain
                    class="pa-0 font-weight-light text-decoration-underline text-capitalize"
                >
                  Resend Code {{ timerCount > 0 ? "(" + timerCount + ")" : "" }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn
                    class="text--secondary"
                    block
                    color="secondary"
                    depressed
                    large
                    rounded
                    @click="verifyCode()"
                >
                  Submit
                  <v-icon
                      class="text--primary  ml-2"
                      dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-window-item>
          <v-window-item>
            <v-row justify="center">
              <v-col cols="10">
                <v-text-field
                    v-model="email"
                    id="email"
                    label="Email"
                    filled
                    background-color="primary"
                    color="textDarkBg"
                    rounded
                    type="email"
                    :rules="[rules.required]"
                    dense></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col cols="10">
                <v-btn
                    class="text--secondary"
                    block
                    color="secondary"
                    depressed
                    rounded
                    large
                    @click="saveAndResend()"
                >
                  Save and Resend
                  <v-icon
                      class="text--primary  ml-2"
                      dark>
                    mdi-arrow-right
                  </v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-window-item>
        </v-window>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Vue from 'vue';
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
  mixins:[loginRegisterMixin],
  data() {
    return {
      window: 0,
      verificationCode: '',
      email: this.$session.get('user').email,
      timerCount: 0,
      rules: {
        required: value => !!value || 'Required.',
      }
    };
  },
  watch: {
    timerCount: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.timerCount--;
          }, 1000);
        }
      },
      immediate: true // This ensures the watcher is triggered upon creation
    }
  },
  created() {
    if (!this.checkUserSignedIn())
      this.$router(Vue.prototype.$appRoutes.SignIn)
    else {
      var userStatus = this.getUserStatus();
      if(userStatus != Vue.prototype.$userStatus.new && userStatus != 0) //continue in this page only if user status is new
        this.$router.push(Vue.prototype.$appRoutes.Home)
    }
  },
  methods: {
    verifyCode() {
      //this.$router.push(Vue.prototype.$appRoutes.Home)
      this.verifyEmail(this.verificationCode, this.refreshUserData);
    },
    resetTimer() {
      this.timerCount = 20;
    },
    changeEmail() {
      this.window = 1;
    },
    saveAndResend() {
      this.window = 0;
      this.updateEmail(this.email, this.onUpdateEmailSuccess);
      this.getUserData();
    },
    resendCode() {
      this.resendVerificationCode(this.email, this.resetTimer)
    },
    refreshUserData() {
      this.getUserData(this.goToRegistrationFees);
    },
    goToRegistrationFees() {
      this.$router.push(Vue.prototype.$appRoutes.RegistrationFees);
    },
    onUpdateEmailSuccess(message) {
      if(message != 'success') {
        alert(message);
      }
      else
        this.resetTimer();
    }
  },
}
</script>

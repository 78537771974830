import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import CreateGroup from '../views/CreateGroup.vue'
import SignIn from '../views/SignIn.vue'
import SignUp from '../views/SignUp.vue'
import ForgotPassword from '../views/ForgotPassword.vue'
import ChangePassword from '../views/ChangePassword.vue'
import EmailConfirmation from '../views/EmailConfirmation.vue'
import Group from '../views/Group.vue'
import Profile from '../views/Profile.vue'
import JoinGroup from '../views/JoinGroup.vue'
import Members from '../views/Members.vue'
import RegistrationFees from '../views/RegistrationFees.vue'
import Payment from '../views/Payment.vue'
import Withdraw from '../views/Withdraw.vue'
import PaymentResult from '../views/PaymentResult.vue'
import UpdateGroup from '../views/UpdateGroup.vue'
import TermsAndConditions from '../views/TermsAndConditions.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/signin',
    component: SignIn
  },
  {
    path: '/signup',
    name: 'SignUp',
    component: SignUp
  },
  {
    path: '/ForgotPassword/:email',
    name: 'ForgotPassword',
    component: ForgotPassword,
    props: true
  },
  {
    path: '/ChangePassword/:email',
    name: 'ChangePassword',
    component: ChangePassword,
    props: true
  },
  {
    path: '/EmailConfirmation',
    name: 'EmailConfirmation',
    component: EmailConfirmation
  },
  {
    path: '/RegistrationFees',
    name: 'RegistrationFees',
    component: RegistrationFees
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home
  },
  {
    path: '/Profile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/createGroup',
    name: 'CreateGroup',
    component: CreateGroup
  },
  {
    path: '/updateGroup/:groupId',
    name: 'UpdateGroup',
    component: UpdateGroup,
    props: true
  },
  {
    path: '/group',
    name: 'Group',
    component: Group
  },
  {
    path: '/joinGroup/:code',
    name: 'JoinGroup',
    component: JoinGroup,
    props: true
  },
  {
    path: '/members',
    name: 'Members',
    component: Members
  },
  {
    path: '/payment/:groupID',
    name: 'Payment',
    component: Payment,
    props: true
  },
  {
    path:'/withdraw/:groupID',
    name: 'Withdraw',
    component: Withdraw,
    props: true
  },
  {
    path: '/thankyou',
    name: 'PaymentResult',
    component: PaymentResult,
    props: true
  },
  {
    path: '/termsandconditions',
    name: 'TermsAndConditions',
    component: TermsAndConditions,
  },
  {
    path: '/privacypolicy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy,
  },
  {
    path: '*',
    name: 'CatchAll',
    component: SignIn
  }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})

export default router

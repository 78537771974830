import axios from "axios";

export const faizPay = {
    methods: {
        getBankList(onSuccess) {
            axios.get(process.env.VUE_APP_API_FAIZPAY_BANK_LIST)
                .then((response) => {
                    this.$session.set('bankList', response.data);
                    if(onSuccess != null)
                        onSuccess();
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        pay(amount, bankID, accountNumber, sortCode, groupID) {
            var userData = this.$session.get('user');
            if(userData==null) return;
            var data = {
                amount: amount,
                bank_id: bankID,
                accountNumber: accountNumber,
                sortCode: sortCode,
                email: userData.email,
                firstName: userData.name,
                lastName: userData.name,
                phone: userData.phone,
                group_id: groupID
            }
            axios.post(process.env.VUE_APP_API_ROOT + 'api/pay', data)
                .then((response) => {
                    window.open(process.env.VUE_APP_API_FAIZPAY_PAY + '?token=' + response.data, '_self');
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        getWithdrawToken(amount, accountNumber, sortCode, groupID, onSuccess) {
            var data = {
                amount: amount,
                accountNumber: accountNumber,
                sortCode: sortCode,
                group_id: groupID
            }
            axios.post(process.env.VUE_APP_API_ROOT + 'api/withdraw', data)
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess(response.data);
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        createWithdrawRequest(token, onSuccess) {
            var data = new FormData();
            data.append('token',token);
            axios.post(process.env.VUE_APP_API_FAIZPAY_ROOT + 'partner/whip-round/payment', data, {headers: {"Content-Type": "multipart/form-data"}})
                .then((response) => {
                    if(onSuccess != null)
                        onSuccess(response.data);
                }, (error) => {
                    alert(error.response.data.message);
                })
        },
        submitWithdrawRequest(token, groupID, amount, onSuccess) {
            var data = {
                group_id: groupID,
                amount: amount,
                token: token
            }

            axios.post(process.env.VUE_APP_API_ROOT + 'api/submitWithdrawRequest', data)
                .then((response) => {
                    if(response.data == 'false') {
                        alert('There are not enough funds available in the pot, please top up and try again');
                        return;
                    }
                    if(onSuccess != null) {
                        onSuccess();
                    }
                }, (error) => {
                    alert(error.response.data.message);
                })
        }
    }
}
<template>
    <v-form
        ref="form"
        lazy-validation>
        <v-container class="gradient-background">
            <v-row justify="center">
                <v-col cols="2">
                    <router-link :to="{name: prevRoute}" class="text-decoration-none">
                        <v-icon
                            class="text--primary text-h4"
                            dark>
                            mdi-arrow-left
                        </v-icon>
                    </router-link>
                </v-col>
                <v-col cols="7">
                    <v-img
                        class="mt-8 mb-8"
                        src="../assets/logo.png"
                        width="100%"></v-img>
                </v-col>
                <v-col cols="2" />
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                <div>A verification code was sent to your email, please enter the code to change the password</div>

                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="10">
                <v-text-field
                    v-model="verificationCode"
                    label="Verification Code"
                    filled
                    background-color="primary"
                    color="textDarkBg"
                    rounded
                    dense
                    :rules="[rules.required]"></v-text-field>
                </v-col>
            </v-row>
        <v-row justify="center">
            <v-col cols="10">
            <v-btn
                @click="resendCode()"
                :disabled="timerCount>0"
                color="primary"
                plain
                class="pa-0 font-weight-light text-decoration-underline text-capitalize"
            >
                Resend Code {{timerCount>0?timerCount:""}}
            </v-btn>
            </v-col>
        </v-row>
            <v-row justify="center">
                <v-col cols="10">
                <v-btn
                    class="text--secondary text-capitalize"
                    block
                    color="secondary"
                    depressed
                    large
                    rounded
                    @click="verifyCode()"
                    >
                    Verify
                    <v-icon
                    class="text--primary  ml-2"
                    dark>
                    mdi-arrow-right
                    </v-icon>
                </v-btn>
                </v-col>
            </v-row>
        </v-container>
    </v-form>
</template>

<script>
import Vue from 'vue';
import { loginRegisterMixin } from '../mixins/LoginRegisterMixin.js'
export default {
    mixins:[loginRegisterMixin],
    props:['email'],
    data() {
        return {
            verificationCode: '',
            timerCount: 20,
            prevRoute: this.$session.flash.get('prevRoute'),
            rules: {
                required: value => !!value || 'Required.',
            }
        };
    },
    watch: {
        timerCount: {
            handler(value) {
                if (value > 0) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
            },
            immediate: true // This ensures the watcher is triggered upon creation
        }
    },
    beforeRouteEnter(to, from, next) {
        next(vm => {
            if(vm.prevRoute == null) {
                vm.prevRoute = from.name
                vm.$session.flash.set('prevRoute', from.name);
            }
        })
    },
    created() {
        this.forgotPassword(this.email, this.OnForgotPasswordFinish)
    },
    methods: {
        verifyCode() {
            if(this.$refs.form.validate())
                this.verifyForgotCode(this.email, this.verificationCode, this.goToChangePassword);
        },
        resendCode(){
            this.timerCount=20;
            this.forgotPassword(this.email);
        },
        goToChangePassword() {
            this.$router.push(Vue.prototype.$appRoutes.ChangePassword(this.email));
        },
        OnForgotPasswordFinish(message) {
            if(message != 'success') {
                alert(message);
                this.$router.push(Vue.prototype.$appRoutes.SignIn);
            }
        }
    },
}
</script>

<template>
    <router-link :to="url" custom v-slot="{ navigate }">
        <v-card
            width="135px"
            height="169px"
            class="rounded-lg"
            elevation="0"
            @click="navigate">
            <v-img
                :src="require('../assets/Buttons/' + backgroundImg)">
                <v-card-text>
                <v-img
                    src="../assets/Icons/card_icon_holder.png"
                    width="40px"
                    height="40px">
                        <v-img
                            :src="require('../assets/Icons/' + iconImg)"
                            width="24px"
                            height="24px"
                            style="margin-left:8px;margin-right:8px;margin-top:7px">
                        </v-img>
                </v-img>
            </v-card-text>
            <v-card-title class="card-title">
                {{title}}
            </v-card-title>
            <v-card-subtitle class="card-subtitle">
                {{details}}
            </v-card-subtitle>
            </v-img>
        </v-card>
    </router-link>
</template>

<script>
export default {
    props:['url', 'title', 'details', 'backgroundImg', 'iconImg'],
    data() {
        return {

        }
    },
    methods: {

    }
}
</script>

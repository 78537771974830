<template>
    <v-container class="gradient-background">
        <v-row>
            <v-col cols="2" class="pa-2">
                <v-icon
                    class="text--primary text-h4" @click="goToPreviousPage"
                    dark>
                    mdi-arrow-left
                </v-icon>
            </v-col>
            <v-col cols="8" align="center" class="headline-font">
                Privacy Policy
            </v-col>
            <v-col cols="2"></v-col>
        </v-row>
        <v-row>
            <privacy-policy />
        </v-row>
    </v-container>
</template>

<script>
import PrivacyPolicy from '../components/PrivacyPolicy.vue';
export default {
    components: { PrivacyPolicy },
    methods: {
        goToPreviousPage() {
            this.$router.go(-1);
        }
    }
}
</script>
